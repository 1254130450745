//colors
$black: black;
$red: red;
$color_dove_gray_approx: #68696d;
$color_mountain_mist_approx: #999999;
$white: white;
$color_pumice_approx: #c7c7c7;
$alto: #dbdbdb;

//urls
$url_0: url(/sites/maccosmetics/themes/mac_base/images/fallbacks/corner-triangle.gif);

//fonts
$font_0: tstar_mono_roundregular;
$font_1: Helvetica;
$font_2: Arial;
$font_3: Sans-Serif;
$font_4: ano-bold_regular;
$font_5: tstar_mono_roundbol;

//@extend-elements
//original selectors
//#email-signup, #email-singup-thanks-page, #contact-us, #contact-us-thanks-page, #email-unsubscribe, #email-unsubscribe-thanks-page
@mixin extend_1 {
  max-width: 1024px;
  margin: 20px auto;
  overflow: hidden;
  padding: 0 20px;
}

//original selectors
//#email-signup h2, #contact-us h2, #email-unsubscribe h2
@mixin extend_2 {
  font-size: 1rem;
  font-family: $font_0, $font_1, $font_2, $font_3;
  line-height: 1.5;
  text-transform: none;
}

//original selectors
//#email-signup .form-type-textfield, #contact-us .form-type-textfield
@mixin extend_3 {
  float: left;
  margin: 10px 3% 10px 0;
  width: 47%;
}

//original selectors
//#email-signup #edit-submit, #contact-us #edit-submit
@mixin extend_4 {
  float: left;
  clear: both;
  text-decoration: none;
  width: 215px;
}

//original selectors
//#edit-form-question-type .form-item-form-QUESTION-TYPE, #edit-form-question .form-item-form-QUESTION
@mixin extend_5 {
  display: inline-block;
  width: 210px;
  vertical-align: top;
}

//original selectors
//.form-item-form-QUESTION-TYPE >label, .form-item-form-COMMENTS >label
@mixin extend_6 {
  font-family: $font_4, $font_1, $font_2, $font_3;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 44px;
  clear: both;
}

//original selectors
//input, textarea, #form-textarea-wrapper, #edit-form-comments
@mixin extend_7 {
  //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
  border-radius: 0;
  appearance: none;
  //Instead of the line below you could use @include box-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  box-shadow: none !important;
}

#email-signup {
  @include extend_1;
  h2 {
    @include extend_2;
  }
  .form-type-textfield {
    @include extend_3;
  }
  .form-type-checkboxes {
    float: left;
    margin: 10px 0;
  }
  #edit-submit {
    @include extend_4;
  }
  .form-type-checkbox .picker-handle {
    top: 0;
  }
  .label-error ~ input {
    border-color: $red;
  }
}

#email-singup-thanks-page {
  @include extend_1;
}

#contact-us {
  @include extend_1;
  h2 {
    @include extend_2;
  }
  .form-type-textfield {
    @include extend_3;
  }
  #edit-submit {
    @include extend_4;
  }
  .newsletter {
    clear: both;
    margin-bottom: 10px;
    float: left;
  }
  .label-error ~ input {
    border-color: $red;
  }
  .form-type-radios.form-item-form-QUESTION-TYPE {
    margin-bottom: 0;
    > label {
      display: block;
      color: $black;
    }
  }
  .form-type-textarea.form-item-form-COMMENTS {
    margin-right: 3%;
    > label {
      display: block;
      color: $black;
    }
  }
}

#contact-us-thanks-page {
  @include extend_1;
}

#email-unsubscribe {
  @include extend_1;
  h2 {
    @include extend_2;
  }
  .form-item-form-Email-Address {
    width: 70%;
    float: left;
    margin-top: 2px;
    overflow: hiddenl;
    > label {
      display: block;
      color: $black;
      display: inline-block;
    }
  }
  #edit-form-email-address {
    width: 80%;
  }
  #edit-submit {
    float: left;
    display: block;
    text-decoration: none;
  }
  .form-required {
    display: none;
  }
}

#email-unsubscribe-thanks-page {
  @include extend_1;
}

@media only screen and(max-width: 768px) {
  #email-unsubscribe #edit-form-email-address {
    width: 75%;
  }
}

.form-item-form-COMMENTS {
  > label {
    @include extend_6;
  }
  .label-error ~ div textarea {
    border-color: $red;
  }
}

#edit-form-question-type {
  position: relative;
  overflow: hidden;
  .form-item-form-QUESTION-TYPE {
    @include extend_5;
  }
}

#edit-form-question {
  position: relative;
  overflow: hidden;
  .form-item-form-QUESTION {
    @include extend_5;
  }
}

.email-signup__required-note {
  margin-top: 10px;
}

.unique-medium-snowflake {
  padding-top: 14.2% !important;
}

.site-footer--top {
  font-size: 1rem;
  padding: 20px 0 50px;
  .block--footer-extra {
    .social-links {
      li {
        float: left;
        margin: 0 18px 0 0;
      }
      a {
        font-size: 18px;
      }
      ul {
        margin: 0 0 31px;
      }
    }
    .language-picker a {
      border-bottom: none;
    }
  }
  .grid-container--6 .grid--item:nth-of-type(6n + 1) {
    clear: none;
    float: left;
    margin-left: 16.5%;
    margin-right: -100%;
    width: 16.6667%;
  }
  .grid-container--6 .grid--item:nth-of-type(6n + 2) {
    clear: none;
    float: left;
    margin-left: 30.2%;
    margin-right: -100%;
    width: 16.6667%;
  }
  .grid-container--6 .grid--item:nth-of-type(6n + 3) {
    clear: none;
    float: left;
    margin-left: 47.2%;
    margin-right: -100%;
    width: 16.6667%;
  }
  .grid-container--6 .grid--item:nth-of-type(6n + 4) {
    clear: none;
    float: left;
    margin-left: 62.7%;
    margin-right: -100%;
    width: 25.667%;
  }
}

.down_arrows {
  padding-left: 8px;
}

.site-header__menu {
  clear: none;
  float: left;
  margin-left: 16.6667%;
  margin-right: 0;
  width: 58.3333%;
}

.site-header__suffix {
  clear: none;
  margin-left: 36px;
  width: auto;
  float: right;
  margin-right: 3px;
}

.flag-icon-sg {
  margin-left: 0;
}

.site-footer--bottom {
  padding: 0;
  .node-menu {
    padding-left: 16.2%;
  }
  .menu > li.menu-item-container {
    padding-left: 0.2em;
  }
}

.store-locator {
  .results_panel {
    .store-locator__submit2 {
      margin-top: 10px;
    }
    .city2 {
      border: 1px solid $color_dove_gray_approx;
      padding-left: 10px;
    }
  }
  .form--search--advanced {
    .toggle::after {
      font-size: 15px;
      text-decoration: none;
      vertical-align: baseline;
    }
    .toggle {
      right: 30%;
    }
  }
  .international-store {
    border-bottom: none;
    color: $white;
    display: block;
    text-align: center;
    text-decoration: underline;
    position: absolute;
    right: 20%;
    padding-top: 10px;
  }
}

.select2-container {
  &.city {
    width: 100%;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    border-width: 1px;
    font-size: 12px;
    text-transform: uppercase;
    border-color: $color_mountain_mist_approx;
    border-style: solid;
    outline: 0 none;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: border-color 0.3s ease-in-out 0s;
  }
  .select2-choice {
    .select2-arrow {
      top: -10px;
      right: 12px;
      b {
        background: none;
        font-size: 34px;
        font-weight: normal;
      }
    }
    > .select2-chosen {
      margin-right: 0;
      margin-left: 17px;
    }
  }
}

input {
  @include extend_7;
  &.btn.btn--no-border.store-locator__submit2 {
    width: 100%;
  }
}

.email-sms-signup-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

@media (min-width: 30.0625em) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5%;
  }
}

.franchise-content-blk {
  height: 345px;
  margin-top: 100px;
  background-color: $black;
  color: $white;
  width: 238px;
  text-align: center;
  padding-top: 38%;
}

.franch_name {
  text-transform: uppercase;
  font-size: 17px;
  font-family: $font_4, $font_1, $font_2, $font_3;
  margin-bottom: 8px;
}

.store_link {
  margin-top: 26px;
  a {
    color: $white;
    border: 1px solid $white;
    padding: 16px 6px;
    font-size: 11px;
    font-family: $font_5;
    &:hover {
      text-decoration: underline;
    }
  }
}

body {
  &.page-products-products-franchises-prep-prime.device-pc .product--teaser .product__name {
    padding-right: 55px;
  }
  &.page-products-products-franchises-lightful-c.device-pc .product--teaser .product__name {
    padding-right: 55px;
  }
}

@media (min-width: 60.5em) {
  .store-locator .form--search--advanced .form--search .form--inline .form-type-textfield {
    width: 185px;
  }
}

@media only screen and(min-device-width: 768px) and(orientation: portrait) {
  .site-header__menu {
    width: 25% !important;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    clear: none;
  }
  .store-locator {
    .form--search--advanced {
      .toggle-wrapper {
        margin: 21px 0 66px 0 !important;
      }
      .geo_container {
        position: absolute;
        left: 85%;
        top: 167px;
      }
      .toggle {
        color: $black;
        padding: 0;
        right: 40% !important;
        text-align: center;
      }
      .form--search .form--inline .btn {
        bottom: 0;
        left: 6%;
        position: absolute;
        width: 78%;
        right: auto;
      }
    }
    .store-locator-landing__form .store-locator-landing__title {
      font-size: 30px;
      margin: 15px 0;
    }
  }
  i.icon--envelope.site-email-signup__link-icon {
    font-size: 18px;
  }
  .footer-social--mobile .social-links {
    a {
      font-size: 20px;
    }
    li {
      display: inline-block;
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .site-footer--bottom .node-menu {
    padding-left: 0;
  }
  .custom-grid .custom-grid__item-inner {
    border-bottom: none;
  }
  .store-locator-landing__form .form--inline:before {
    content: '';
  }
}

textarea {
  @include extend_7;
}

#form-textarea-wrapper {
  @include extend_7;
}

#edit-form-comments {
  @include extend_7;
}

#edit-form-email-promotions dd {
  margin-left: 0;
}

#edit-form-agree-policy dd {
  margin-left: 0;
}

.form-item-form-QUESTION-TYPE > label {
  @include extend_6;
}

.bestsellers-nav header.submenu__header {
  border-right: none;
}

.grid--mpp.responsive-container.bestsellers {
  margin-top: 5%;
}

.picker-radio.picker.checked .picker-handle .picker-flag {
  margin: 2px;
}

.footer-lang--mobile .language > a {
  border-bottom: none;
}

.custom-grid__item-inner .basic-text-block-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.custom-grid .unique-tiny-snowflake {
  padding-top: 14% !important;
}

.franch_desc span {
  font-size: 11px;
}

#product--prod_id-PROD29829 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD35126 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD17364 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19310 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19311 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19309 h3.product__name {
  padding-right: 95px;
}

.ambient-header--raised .multi-use-tout.mobile_hidden {
  display: block !important;
}

#email_sms_signup input {
}

@media only screen and(min-device-width: 320px) and(max-device-width: 480px) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5% !important;
  }
}

.site-header__suffix {
  float: right;
  margin-right: 3px;
}

body {
  &.ajax-wait {
    cursor: auto !important;
    * {
      cursor: auto !important;
    }
  }
  &.device-pc .select2-container .select2-choice {
    .select2-arrow b {
      font-size: 30px;
    }
    > .select2-chosen {
      float: left !important;
      color: $black;
      text-decoration: underline;
    }
  }
}

.email-sms-signup-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.unique-medium-snowflake {
  padding-top: 14.2% !important;
}

@media only screen and(max-device-width: 480px) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5% !important;
  }
}

@media all and(device-width: 768px) and(device-height: 1024px) and(orientation: portrait) {
  .store-locator {
    .international-store {
      color: $black;
      margin: 0;
      padding: 0;
      right: 40%;
    }
    .form--search--advanced .toggle-wrapper {
      display: block;
    }
  }
}

@media only screen and(min-device-width: 768px) and(max-device-width: 1024px) {
  .form--search .form-item.form-type-textfield {
    background-color: $white;
  }
}

@media only screen and(min-device-width: 768px) and(orientation: portrait) {
  .store-locator {
    .form--search--advanced {
      .toggle-wrapper {
        margin: 21px 0 66px;
      }
      .geo_container {
        position: absolute;
        left: 85%;
        top: 165px;
      }
      .toggle {
        color: $black;
        padding: 0;
        right: 40% !important;
        text-align: center;
      }
      .form--search .form--inline .btn {
        bottom: 0;
        left: 6%;
        position: absolute;
        width: 78%;
        right: auto;
      }
    }
    .results_panel .results_side-title {
      text-align: center;
    }
    .store-locator-landing__form .store-locator-landing__title {
      font-size: 30px;
      margin: 15px 0;
    }
  }
  select {
    background: $url_0;
    padding: 0 7% 0 26px;
    background-repeat: no-repeat;
    background-position: 95.5% 58%;
    background-size: 11px;
    border-color: $alto;
  }
  .site-container {
    min-height: 543px;
    min-width: 320px;
  }
  .results_side .sub-options label {
    display: none !important;
  }
  .toggle-wrapper.results-page {
    text-align: center;
  }
  input.btn.btn--no-border.store-locator__submit2 {
    width: 88%;
    float: left;
  }
  .store-locator-landing__form .form--inline:before {
    content: '';
  }
  .footer-social-links-v1.social-links {
    li[data-name='pinterest'] {
      margin-right: 0;
    }
    li[data-name='tumblr'] {
      margin-right: 0;
    }
    li[data-name='instagram'] {
      margin-right: 0;
    }
    li[data-name='youtube'] {
      margin-right: 0;
    }
  }
}

@media only screen and(min-device-width: 768px) and(orientation: landscape) {
  .store-locator .form--search--advanced .toggle {
    right: 6%;
  }
  input.btn.btn--no-border.store-locator__submit2 {
    width: 65%;
  }
}

.custom-grid__item-inner .basic-text-block-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.select2-container .select2-choice .select2-arrow {
  top: -11px;
}

.store-locator .form--search--advanced {
  .toggle::after {
    font-size: 15px;
    text-decoration: none;
    vertical-align: baseline;
    content: '';
  }
  .toggle {
    right: 9%;
  }
}

#email-signup {
  .email-signup__content {
    .form-error,
    .label-error {
      overflow: hidden;
      height: 15px;
    }
  }
}

#contact-us {
  .contact-us__content {
    .form-error,
    .label-error {
      overflow: hidden;
      height: 22px;
    }
  }
}

section.customer-service__page-content {
  .responsive-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 72%;
  }
  .basic-responsive-v1 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.basic-formatter-v1 {
  .terms,
  .privacy-policy {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    width: 72%;
  }
}

.customer-service__page {
  h2 {
    margin-top: 0px !important;
  }
}

.section-employment {
  .customer-service__page {
    .customer-service__page-content {
      .basic-responsive-v1 {
        margin-top: 20px !important;
        margin-bottom: 60px !important;
        width: 72%;
      }
    }
  }
}

.store-locator {
  .tout-block-landscape__headline {
    font-size: 36px !important;
  }
}

.field-elc-mpp-content {
  .ambient-header--raised {
    top: auto;
  }
}

.privacy-policy {
  p {
    a:before {
      content: '';
      display: inline-block;
      height: 75px;
      margin: -60px 0 0;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .custom-grid {
    .text-block__headline {
      font-size: 23px;
    }
  }
  .site-footer--top {
    .grid-container--6 {
      .grid--item:nth-of-type(6n + 1) {
        width: 15.667%;
      }
    }
  }
}

#contact-us-thanks-page {
  #myButton {
    background: none;
    border: none;
    text-decoration: underline;
  }
}

.grid--mpp {
  .shade-picker__close {
    .icon--remove {
      display: block;
    }
  }
}

.store-locator {
  .select2-container {
    .select2-arrow {
      top: 0px !important;
    }
  }
  .geo_container__mobile {
    display: none;
  }
}

.store_landing_only {
  .tout-block-landscape__headline {
    display: block;
  }
  .tout-block-landscape__cta {
    font-size: 15px !important;
    font-family: 'tstar_mono_roundregular', Helvetica, Arial, Sans-Serif !important;
    text-decoration: underline !important;
    width: 228px;
  }
  .tout-block-landscape {
    .btn--fixed_width {
      width: auto !important;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .node-elc-mpp {
    .grid--mpp--raised {
      margin-top: 0px !important;
    }
  }
  .section-home,
  .device-pc {
    .macstore-home {
      display: none !important;
    }
  }
  .store_landing_only {
    .tout-block-landscape__caption {
      .tout-block-landscape__cta {
        text-decoration: underline !important;
      }
    }
  }
}

.store-locator {
  .form--search--advanced {
    .form--search .form--inline {
      .store-locator__submit {
        width: auto !important;
      }
    }
  }
}

.custom-grid {
  .unique-medium-snowflake {
    padding-top: 15.2% !important;
  }
  .unique-snowflake {
    padding-top: 43.25% !important;
  }
  .custom-grid__item-inner {
    .basic-text-block-v1 {
      border-bottom: none;
    }
  }
}

.section-email-sign-up {
  .email-signup__header {
    h1 {
      font-size: 36px !important;
    }
  }
}

.section-stores {
  .store_landing_only {
    .tout-block-landscape__headline {
      font-size: 36px !important;
    }
    .tout-block-landscape {
      max-width: 100% !important;
    }
  }
}

.section-mac-pro {
  .multi-use-tout__caption-inner-inner {
    left: 25%;
    right: auto;
    text-align: left;
    width: 75%;
  }
  .multi-use-tout__cta {
    width: 228px;
  }
}

.field-elc-mpp-content {
  .product__detail {
    .prd_details_desc {
      .shade-picker__close {
        padding-top: 8px !important;
      }
    }
  }
  .grid--mpp {
    .grid--mpp__item {
      .tout-block-portrait__cta {
        width: 228px !important;
      }
    }
  }
}

.store-locator {
  .results_panel {
    .results_side {
      .doors_results-wrapper {
        height: 510px;
      }
    }
  }
}

.section-counterfeit-education {
  .customer-service__page-content {
    .basic-responsive-v1 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.grid--mpp {
  .shade-picker__close {
    .icon--remove {
      font-size: 12px;
    }
  }
}

.section-mac-pro {
  .customer-service__page-content {
    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .basic-responsive-v1 {
      p {
        width: 72%;
      }
    }
  }
}

.product--teaser {
  .product__detail {
    .prd_details_desc {
      .product__description-complete {
        text-transform: none !important;
      }
    }
  }
}

.section-email-sign-up {
  .email-signup-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: gray;
    opacity: 0.6;
    filter: alpha(opacity=60);
  }
  .email-signup-progress {
    font-size: 18px;
    font-weight: bold;
    color: white;
    position: absolute;
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -25px;
  }
}
