.product__sku-details {
  .product__footer {
    border-top: none;
    .product__shade-column1 {
      z-index: 5;
      bottom: 30px;
    }
    .learn_more_btn {
      width: 100%;
      height: auto;
      line-height: revert;
      min-width: 100%;
      padding: 2px 10px;
      margin: 2px 0;
    }
    .store_btn {
      a {
        &:visited {
          color: $white;
        }
      }
    }
  }
}
