//colors
$black: black;
$red: red;
$color_dove_gray_approx: #68696d;
$color_mountain_mist_approx: #999;
$white: white;
$color_pumice_approx: #c7c7c7;
$alto: #dbdbdb;

#email-unsubscribe {
  @include extend_1;
  h2 {
    @include extend_2;
  }
  .form-item-form-Email-Address {
    width: 70%;
    float: left;
    margin-top: 2px;
    overflow: hiddenl;
    > label {
      color: $black;
      display: inline-block;
    }
  }
  #edit-form-email-address {
    width: 80%;
  }
  #edit-submit {
    float: left;
    display: block;
    text-decoration: none;
  }
  .form-required {
    display: none;
  }
}

.store-locator {
  .results_panel {
    .store-locator__submit2 {
      margin-top: 10px;
    }
    .city2 {
      border: 1px solid $color_dove_gray_approx;
      padding-left: 10px;
    }
  }
  .form--search--advanced {
    .toggle::after {
      font-size: 15px;
      text-decoration: none;
      vertical-align: baseline;
      content: '';
    }
    .toggle {
      right: 9%;
    }
  }
  .international-store {
    border-bottom: none;
    color: $white;
    display: block;
    text-align: center;
    text-decoration: underline;
    position: absolute;
    right: 20%;
    padding-top: 10px;
  }
}

.select2-container {
  &.city {
    width: 100%;
    //Instead of the line below you could use @include border-radius($radius, $vertical-radius)
    border-radius: 0;
    border-width: 1px;
    font-size: 12px;
    text-transform: uppercase;
    border-color: $color_mountain_mist_approx;
    border-style: solid;
    outline: 0 none;
    //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
    transition: border-color 0.3s ease-in-out 0s;
  }
  .select2-choice {
    .select2-arrow {
      top: -11px;
      right: 12px;
      b {
        background: none;
        font-size: 34px;
        font-weight: normal;
      }
    }
    > .select2-chosen {
      margin-right: 0;
      margin-left: 17px;
    }
  }
}

input {
  @include extend_7;
  &.btn.btn--no-border.store-locator__submit2 {
    width: 100%;
  }
}

.email-sms-signup-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

@media (min-width: 30.0625em) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5%;
  }
}

.franchise-content-blk {
  height: 345px;
  margin-top: 100px;
  background-color: $black;
  color: $white;
  width: 238px;
  text-align: center;
  padding-top: 38%;
}

.franch_name {
  text-transform: uppercase;
  font-size: 17px;
  font-family: $font_4, $font_1, $font_2, $font_3;
  margin-bottom: 8px;
}

.store_link {
  margin-top: 26px;
  a {
    color: $white;
    border: 1px solid $white;
    padding: 16px 6px;
    font-size: 11px;
    font-family: $font_5;
    &:hover {
      text-decoration: underline;
    }
  }
}

body {
  &.page-products-products-franchises-prep-prime.device-pc .product--teaser .product__name {
    padding-right: 55px;
  }
  &.page-products-products-franchises-lightful-c.device-pc .product--teaser .product__name {
    padding-right: 55px;
  }
  &.ajax-wait {
    cursor: auto !important;
    * {
      cursor: auto !important;
    }
  }
  &.device-pc .select2-container .select2-choice {
    .select2-arrow b {
      font-size: 30px;
    }
    > .select2-chosen {
      float: left !important;
      color: $black;
      text-decoration: underline;
    }
  }
}

@media (min-width: 60.5em) {
  .store-locator .form--search--advanced .form--search .form--inline .form-type-textfield {
    width: 185px;
  }
}

@media only screen and(min-device-width: 768px) and(orientation: portrait) {
  .site-header__menu {
    width: 25% !important;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    clear: none;
  }
  i.icon--envelope.site-email-signup__link-icon {
    font-size: 18px;
  }
  .footer-social--mobile .social-links {
    a {
      font-size: 20px;
    }
    li {
      display: inline-block;
      padding-left: 15px;
      &:first-child {
        padding-left: 0;
      }
    }
  }
  .site-footer--bottom .node-menu {
    padding-left: 0;
  }
  .custom-grid .custom-grid__item-inner {
    border-bottom: none;
  }
}

textarea {
  @include extend_7;
}

#form-textarea-wrapper {
  @include extend_7;
}

#edit-form-comments {
  @include extend_7;
}

#edit-form-email-promotions dd {
  margin-left: 0;
}

#edit-form-agree-policy dd {
  margin-left: 0;
}

.form-item-form-QUESTION-TYPE > label {
  @include extend_6;
}

.bestsellers-nav header.submenu__header {
  border-right: none;
}

.grid--mpp.responsive-container.bestsellers {
  margin-top: 5%;
}

.picker-radio.picker.checked .picker-handle .picker-flag {
  margin: 2px;
}

.footer-lang--mobile .language > a {
  border-bottom: none;
}

.custom-grid__item-inner .basic-text-block-v1 {
  border-bottom: 1px solid $color_pumice_approx;
}

.custom-grid .unique-tiny-snowflake {
  padding-top: 14% !important;
}

.franch_desc span {
  font-size: 11px;
}

#product--prod_id-PROD29829 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD35126 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD17364 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19310 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19311 h3.product__name {
  padding-right: 95px;
}

#product--prod_id-PROD19309 h3.product__name {
  padding-right: 95px;
}

@media only screen and(min-device-width: 320px) and(max-device-width: 480px) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5% !important;
  }
}

@media only screen and(max-device-width: 480px) {
  .custom-grid .unique-tiny-snowflake {
    padding-top: 37.5% !important;
  }
}

@media all and(device-width: 768px) and(device-height: 1024px) and(orientation: portrait) {
  .store-locator {
    .international-store {
      color: $black;
      margin: 0;
      padding: 0;
      right: 40%;
    }
    .form--search--advanced .toggle-wrapper {
      display: block;
    }
  }
}

@media only screen and(min-device-width: 768px) and(max-device-width: 1024px) {
  .form--search .form-item.form-type-textfield {
    background-color: $white;
  }
}

@media only screen and(min-device-width: 768px) and(orientation: portrait) {
  .store-locator {
    .form--search--advanced {
      .toggle-wrapper {
        margin: 21px 0 66px 0 !important;
      }
      .geo_container {
        position: absolute;
        left: 85%;
        top: 165px;
      }
      .toggle {
        color: $black;
        padding: 0;
        right: 40% !important;
        text-align: center;
      }
      .form--search .form--inline .btn {
        bottom: 0;
        left: 6%;
        position: absolute;
        width: 78%;
        right: auto;
      }
    }
    .results_panel .results_side-title {
      text-align: center;
    }
    .store-locator-landing__form .store-locator-landing__title {
      font-size: 30px;
      margin: 15px 0;
    }
  }
  select {
    background: $url_0;
    padding: 0 7% 0 26px;
    background-repeat: no-repeat;
    background-position: 95.5% 58%;
    background-size: 11px;
    border-color: $alto;
  }
  .site-container {
    min-height: 543px;
    min-width: 320px;
  }
  .results_side .sub-options label {
    display: none !important;
  }
  .toggle-wrapper.results-page {
    text-align: center;
  }
  input.btn.btn--no-border.store-locator__submit2 {
    width: 88%;
    float: left;
  }
  .store-locator-landing__form .form--inline:before {
    content: '';
  }
  .footer-social-links-v1.social-links {
    li[data-name='pinterest'] {
      margin-right: 0;
    }
    li[data-name='tumblr'] {
      margin-right: 0;
    }
    li[data-name='instagram'] {
      margin-right: 0;
    }
    li[data-name='youtube'] {
      margin-right: 0;
    }
  }
}

@include breakpoint($bp--large-down) {
  .store-locator .form--search--advanced .toggle {
    right: 6%;
  }
  input.btn.btn--no-border.store-locator__submit2 {
    width: 65%;
  }
}

.ambient-header--raised .multi-use-tout.mobile_hidden {
  display: block !important;
}
