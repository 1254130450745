.field-content {
  .store-locator {
    .store-locator-landing__form {
      .form--search--advanced {
        width: 100%;
        margin-inline-start: auto;
        .form--search {
          margin-block: 4px 0;
          margin-inline: 0 4px;
        }
        .toggle {
          position: initial;
        }
        .top {
          text-align: center;
          .responsive-container {
            .form-type-textfield {
              width: 200px;
              .select2-container {
                margin: 1px;
              }
            }
            .geo_container {
              margin-block: 4px 20px;
              margin-inline: 0 4px;
            }
            .icon--search {
              margin: 22px;
            }
          }
        }
      }
    }
  }
}
